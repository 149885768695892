import axios from 'axios'
import { api } from '@/api'
import * as mainService from '@/components/common/mixins/index.js'
import { PORTAL, CYNDX_BASE_URL } from '@/constants'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

let token = null

const axiosInstance = axios.create({
  baseURL: CYNDX_BASE_URL,
  timeout: 0,
  params: {}, // do not remove this, its added to add params later in the config
  headers: {
    'Content-Type': 'application/json',
  },
})

// Request Interceptor
axiosInstance.interceptors.request.use(
  async (conf, request) => {
    const portal = mainService.$getPortal()
    if (portal === PORTAL.ISSUER) {
      const tokenDetails = await getTokenForIssuer(conf.url)
      token = tokenDetails?.external_token
    } else if (portal === PORTAL.CAPTABLE) {
      token = store.getters['auth/cyndxAccessToken']
    } else {
      token = await getTokenForTenant()
    }
    // This header needs confirmation from Cyndx
    // conf.headers.common['x-client-data'] = CYNDX_CLIENT_DATA_TOKEN
    if (token) {
      conf.headers.common.authorization = `Bearer ${token}`
    }
    return conf
  },
  (error) => Promise.reject(error),
)

// Response Interceptor
axiosInstance.interceptors.response.use(null, function (error) {
  const portal = mainService.$getPortal()
  if ((error.response.status === 403 || error.response.status === 401) && portal === PORTAL.CAPTABLE) {
    router.push({ name: 'JwtExpired' })
  }
  return Promise.reject(error)
})

// Token API Calls
export const getTokenForTenant = async () => {
  const opportunityId = store.getters['sharedData/opportunityId']
  const url = `opportunities/${opportunityId}/captable/oauth/token`
  const resp = await api.get(url, null, { base: 'tenant' })
  if (resp && resp.data) {
    token = resp.data.access_token
  }
  return token
}
export const getTokenForIssuer = async (targetUrl, options, apiOpts) => {
  const param = targetUrl && targetUrl.indexOf('organizations') > -1 ? '?skip_checks=true' : ''
  const apiResponse = await api.get(`captable/oauth/token${param}`, param, apiOpts)
  return apiResponse.data
}

// Captable Data
export const getCaptableSummaryData = async (captableId, asOfDate, includeDraft) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}?date=${asOfDate}&include_valid_drafts=${includeDraft}`
  return axiosInstance.get(url)
}
export const getCaptableHoldersData = async (captableId, asOfDate, includeDraft) => {
    const url = CYNDX_BASE_URL.concat(`/v1/cap-tables/${captableId}/holders?date=${asOfDate}&include_valid_drafts=${includeDraft}`)
    return axiosInstance.get(url)
}
export const getCaptableFinanceSummary = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing`
  return axiosInstance.get(url)
}

// User
export const retriveIssuerById = async (issuerId) => {
  try {
    const url = `${CYNDX_BASE_URL}/v1/user-roles/${issuerId}`
    const res = await axiosInstance.get(url)
    return res.data
  } catch (err) {
    return err
  }
}
export const initializeIssuerById = async (issuerId) => {
  try {
    const url = `${CYNDX_BASE_URL}/v1/user-roles/${issuerId}`
    const res = await axiosInstance.post(url, null)
    return res.data
  } catch (err) {
    return err
  }
}

export const AddIssuerById = async (issuerId, data) => {
  try {
    const url = `${CYNDX_BASE_URL}/v1/user-roles/${issuerId}/add-role`
    const res = await axiosInstance.post(url, data)
    return res.data
  } catch (err) {
    return err
  }
}

// Financing Rounds
export const draftFinancingRound = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds`
  return axiosInstance.post(url, data)
}
export const publishFinancingRound = async (captableId, financingRoundId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds/${financingRoundId}/publish`
  return axiosInstance.patch(url)
}
export const getAllFinancingRounds = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds`
  return axiosInstance.get(url)
}
export const getFinancingRoundById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds/${id}`
  return axiosInstance.get(url)
}
export const updateFinancingRound = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds/${id}`
  return axiosInstance.patch(url, data)
}
export const deleteFinancingRoundById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/financing-rounds/${id}`
  return axiosInstance.delete(url)
}

// Share Terms
export const draftSimpleShareTerm = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms`
  return axiosInstance.post(url, data)
}
export const publishShareTerm = async (captableId, shareTermId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms/${shareTermId}/publish`
  return axiosInstance.patch(url)
}
export const getShareTermList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms`
  return axiosInstance.get(url)
}
export const getShareTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms/${id}`
  return axiosInstance.get(url)
}
export const updateShareTerm = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms/${id}`
  return axiosInstance.patch(url, data)
}
export const deleteShareTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/share-terms/${id}`
  return axiosInstance.delete(url)
}

// Organization
export const createOrganizationById = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/organizations/${captableId}`
  return axiosInstance.post(url, data)
}
export const getOrganizationById = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/organizations/${captableId}`
  return axiosInstance.get(url)
}

// Convertible Term
export const getConvertibleTermList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms`
  return axiosInstance.get(url)
}
export const draftConvertibleTerm = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms`
  return axiosInstance.post(url, data)
}
export const publishConvertibleTerm = async (captableId, convertibleTermId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms/${convertibleTermId}/publish`
  return axiosInstance.patch(url)
}
export const getConvertibleTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms/${id}`
  return axiosInstance.get(url)
}
export const updateConvertibleTermById = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms/${id}`
  return axiosInstance.patch(url, data)
}

export const deleteConvertibleTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertible-terms/${id}`
  return axiosInstance.delete(url)
}

// Warrant Terms
export const getWarrantTermList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms`
  return axiosInstance.get(url)
}
export const draftWarrantTerm = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms`
  return axiosInstance.post(url, data)
}
export const publishWarrantTerm = async (captableId, warrantTermId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms/${warrantTermId}/publish`
  return axiosInstance.patch(url)
}
export const getWarrantTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms/${id}`
  return axiosInstance.get(url)
}
export const updateWarrantTermById = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms/${id}`
  return axiosInstance.patch(url, data)
}

export const deleteWarrantTermById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrant-terms/${id}`
  return axiosInstance.delete(url)
}

// Investor Profile
export const getIssuerProfileList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/profiles`
  return axiosInstance.get(url)
}
export const draftProfile = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/profiles`
  return axiosInstance.post(url, data)
}
export const publishProfile = async (captableId, profileId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/profiles/${profileId}/publish`
  return axiosInstance.patch(url)
}
export const getProfileById = async (captableId, profileId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/profiles/${profileId}`
  return axiosInstance.get(url)
}

// Issued Securities
export const getIssuedShareList = async (captableId, isIssued) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares?is_issued=${isIssued}`
  return axiosInstance.get(url)
}
export const getIssuedWarrantList = async (captableId, isIssued) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants?is_issued=${isIssued}`
  return axiosInstance.get(url)
}
export const getIssuedConvertibleList = async (captableId, isIssued) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles?is_issued=${isIssued}`
  return axiosInstance.get(url)
}
export const getOptionsList = async (captableId, isIssued) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options?is_issued=${isIssued}`
  return axiosInstance.get(url)
}

// Draft Securities
export const draftWarrants = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants`
  return axiosInstance.post(url, data)
}
export const draftShares = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares`
  return axiosInstance.post(url, data)
}
export const draftConvertibles = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles`
  return axiosInstance.post(url, data)
}
export const draftOption = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options`
  return axiosInstance.post(url, data)
}

// Issue Securities
export const issueWarrants = async (captableId, warrantId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${warrantId}/issue`
  return axiosInstance.patch(url)
}
export const issueShares = async (captableId, shareId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}/issue`
  return axiosInstance.patch(url)
}
export const issueConvertibles = async (captableId, convertibleId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles/${convertibleId}/issue`
  return axiosInstance.patch(url)
}
export const issueOptions = async (captableId, optionId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}/issue`
  return axiosInstance.patch(url)
}

// Share Actions
// Transfer
export const transferShare = async (captableId, shareId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}/transfer`
  return axiosInstance.patch(url, data)
}
// Repurchase
export const repurchaseShare = async (captableId, shareId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}/repurchase`
  return axiosInstance.patch(url, data)
}
// Cancel
export const cancelShare = async (captableId, shareId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}/cancel`
  return axiosInstance.patch(url, data)
}
// Delete
export const deleteShare = async (captableId, shareId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}`
  return axiosInstance.delete(url)
}
// Modify
export const modifyShare = async (captableId, shareId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}`
  return axiosInstance.patch(url, data)
}

// Warrant Actions
// Modify
export const modifyWarrant = async (captableId, warrantId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${warrantId}`
  return axiosInstance.patch(url, data)
}
// Delete
export const deleteWarrant = async (captableId, warrantId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${warrantId}`
  return axiosInstance.delete(url)
}
// Exercise
export const exerciseWarrant = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${id}/exercise`
  return axiosInstance.patch(url, data)
}
// Cancel
export const cancelWarrant = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${id}/cancel`
  return axiosInstance.patch(url, data)
}

// Retrieve securtities by Id
// Warrant
export const retrieveWarrantById = async (captableId, warrantId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/warrants/${warrantId}`
  return axiosInstance.get(url)
}
// share
export const retrieveShareById = async (captableId, shareId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/shares/${shareId}`
  return axiosInstance.get(url)
}
// convertibles
export const retrieveConvertibleById = async (captableId, shareId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles/${shareId}`
  return axiosInstance.get(url)
}
// Option
export const retrieveOptionById = async (captableId, optionId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}`
  return axiosInstance.get(url)
}

// Convertible Actions
// Modify
export const modifyConvertible = async (captableId, convertibleId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles/${convertibleId}`
  return axiosInstance.patch(url, data)
}
// Delete
export const deleteConvertible = async (captableId, convertibleId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles/${convertibleId}`
  return axiosInstance.delete(url)
}
// Cancel
export const cancelConvertible = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/convertibles/${id}/cancel`
  return axiosInstance.patch(url, data)
}

// Option Actions
// Modify
export const modifyOption = async (captableId, optionId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}`
  return axiosInstance.patch(url, data)
}
// Exercise
export const exerciseOption = async (captableId, optionId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}/exercise`
  return axiosInstance.patch(url, data)
}
// Delete
export const deleteOption = async (captableId, optionId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}`
  return axiosInstance.delete(url)
}
// Cancel
export const cancelOption = async (captableId, optionId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}/cancel`
  return axiosInstance.patch(url, data)
}
// Terminate
export const terminateOption = async (captableId, optionId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/options/${optionId}/terminate`
  return axiosInstance.patch(url, data)
}

// Equity Incentive Plans
export const getEipList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans`
  return axiosInstance.get(url)
}
export const draftEip = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans`
  return axiosInstance.post(url, data)
}
export const deleteEipById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans/${id}`
  return axiosInstance.delete(url)
}
export const getEipById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans/${id}`
  return axiosInstance.get(url)
}
export const updateEipById = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans/${id}`
  return axiosInstance.patch(url, data)
}
export const publishEip = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans/${id}/publish`
  return axiosInstance.patch(url)
}
export const amendPlan = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/equity-incentive-plans/${id}/amend`
  return axiosInstance.patch(url, data)
}

// Manage Vesing schedules
export const getVesingScheduleList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/vesting-schedules`
  return axiosInstance.get(url)
}
export const draftVestingSchedule = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/vesting-schedules`
  return axiosInstance.post(url, data)
}
export const getVestingScheduleById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/vesting-schedules/${id}`
  return axiosInstance.get(url)
}
export const updateVestingScheduleById = async (captableId, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/vesting-schedules/${id}`
  return axiosInstance.patch(url, data)
}
export const deleteVestingScheduleById = async (captableId, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/vesting-schedules/${id}`
  return axiosInstance.delete(url)
}

// Scenarios
export const getScenariosList = async (captableId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios`
  return axiosInstance.get(url)
}
export const getScenariosById = async (captableId, scenarioId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios/${scenarioId}`
  return axiosInstance.get(url)
}
export const updateScenarioById = async (captableId, scenarioId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios/${scenarioId}`
  return axiosInstance.patch(url, data)
}
export const getWaterFallById = async (captableId, scenarioId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios/${scenarioId}/waterfall`
  return axiosInstance.get(url)
}

// Add Scenario
export const addScenario = async (captableId, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios`
  return axiosInstance.post(url, data)
}
export const deleteScenarioById = async (captableId, scenarioId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios/${scenarioId}`
  return axiosInstance.delete(url)
}

// Compare Scenario
export const waterfall = async (captableId, scenarioId) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/scenarios/${scenarioId}/waterfall`
  return axiosInstance.get(url)
}

// Notes
export const addNotes = async (captableId, type, id, note) => {
  const notesData = {
    key: 'notes',
    value: { notes: note },
  }
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/${type}/${id}/metadata/notes`
  return axiosInstance.put(url, notesData)
}
export const getNote = async (captableId, type, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/${type}/${id}/metadata/notes`
  return axiosInstance.get(url)
}

// Document
export const createDocument = async (captableId, type, id, data) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/${type}/${id}/document-store`
  return axiosInstance.post(url, data)
}
export const uploadDocument = async (id, data) => {
  const url = `v1/document-store/${id}`
  const formData = new FormData()
  formData.append('file', data)
  return await api.upload(url, formData, {}, 'post')
}
export const getDocuments = async (captableId, type, id) => {
  const url = `${CYNDX_BASE_URL}/v1/cap-tables/${captableId}/${type}/${id}/document-store`
  return axiosInstance.get(url)
}
export const deleteDocuments = async (id) => {
  const url = `${CYNDX_BASE_URL}/v1/document-store/${id}`
  return axiosInstance.delete(url)
}
export const downloadDoc = async (id) => {
  const url = `${CYNDX_BASE_URL}/v1/document-store/${id}`
  return axiosInstance.get(url)
}

// Create Cyndx Organization By ID
const getReqDataForCyndxAPI = (orgName, orgId, foundedOn) => {
  return {
    name: orgName,
    owner: {
      id: orgId,
      type: 'organization',
    },
    address1: '',
    address2: '',
    city: '',
    founded_on: moment(foundedOn).format('YYYY-MM-DD'),
    phone_number: '',
    phone_number_ext: '',
    state: '',
    country: '',
    zipcode: '',
    domain: '',
  }
}
export const createNewOrg = async (orgName, orgId, foundedOn) => {
  const reqData = getReqDataForCyndxAPI(orgName, orgId, foundedOn)
  return await createOrganizationById(orgId, reqData)
}
export const checkIfOrganizationExist = async (orgId) => {
  try {
    const resp = await getOrganizationById(orgId)
    return !!resp?.data
  } catch (err) {
    return false
  }
}
